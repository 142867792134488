<template>
  <div id="chilldoge">
    <main id="main-content">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@font-face {
  font-family: 'Comic Sans MS';
  src: url("assets/comic.ttf");
}

#app {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: bold;
  text-align: center;
  position: relative;
  overflow: hidden;
}

body {
  margin: 0px;
}

</style>
